<template>
<!-- 상태 변경 팝업 -->
<transition name="modal" appear>
    <div class="modal modal-overlay" v-if="onModifyModal" @click.self="handleWrapperClick">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" style="width:1200px">
                <p class="bold dp-inblock h-30px font-23">수정 하기</p>
                <div class="con">
                    <div class="con_table mt-20">
                        <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00 w-100per">
                            <tbody>
                                <tr>
                                    <th>고객명</th>
                                    <td class="left pl-30">
                                        {{name}}
                                    </td>
                                    <th>성별</th>
                                    <td class="left pl-20">
                                        <select v-model="gender" class="w-20px" disabled>
                                            <option value="">성별</option>
                                            <option value="남자">남</option>
                                            <option value="여자">여</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>생년월일</th>
                                    <td class="left pl-30">
                                        <input type="text" v-model="birth" disabled/>
                                    </td>
                                    <th>연령선택</th>
                                    <td class="left pl-20">
                                        <select class="w-120px" disabled v-model="ageRange">
                                             <option value="">연령선택</option>
                                             <option value="10대 이하">10대 이하</option>
                                             <option value="20대">20대</option>
                                             <option value="30대">30대</option>
                                             <option value="40대">40대</option>
                                             <option value="50대이상">50대이상</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>이메일</th>
                                    <td class="left pl-30">
                                        <input disabled v-model="email" type="text" class="w-200px">
                                    </td>
                                    <th>소속</th>
                                    <td class="left">
                                        <div class="app">
                                            <div class="search">
                                                <input type="text" v-model="companyName" class="ml-05" style="width:200px;" id="company" name="company" disabled>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>휴대폰 번호</th>
                                    <td class="left pl-10">
                                        <select disabled class="ml-20" v-model="phone1" id="phone1" name="phone1">
                                            <option value="010">010</option>
                                            <option value="011">011</option>
                                            <option value="016">016</option>
                                            <option value="017">017</option>
                                            <option value="018">018</option>
                                            <option value="019">019</option>
                                        </select> -
                                        <input disabled type="text" v-model="phone2" style="width:100px;" id="phone2" name="phone2" value=""> -
                                        <input disabled type="text" v-model="phone3" style="width:100px;" id="phone3" name="phone3" value="">
                                       
                                    </td>
                                    <th>유입경로</th>
                                    <td class="left pl-20">
                                        <select disabled v-model="idxCrmMetaRoute" class="w-120px">
                                            <option value="-1">유입경로DB</option>
                                            <option v-for="(item, index) of routeList" :key="index" :value="item.idx">{{item.type}}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bold w-100px">예약일자</th>
                                    <td class="left pl-20">
                                        <crm-datepicker class="ml-10" @input="getStaffSchedule()" v-model="consStartDate" :parentDate="consStartDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                                    </td>
                                    <th style="width: 111px;">예약현황 <span class="red">*</span></th>
                                    <td class="left pl-20">
                                        <select v-model="reservationStatus" class="w-120px">
                                            <option value="">예약현황선택</option>
                                            <option value="예약">예약</option>
                                            <option value="취소">취소</option>
                                            <option value="NS">NS</option>
                                            <option value="입실">입실</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bold w-100px">예약시간</th>
                                    <td class="left" colspan="3">
                                        <table class="default_table w-1000px mb-20 mt-20 ml-20">
                                            <tbody>
                                                <tr>
                                                    <th>09:00</th>
                                                    <th>09:30</th>
                                                    <th>10:00</th>
                                                    <th>10:30</th>
                                                    <th>11:00</th>
                                                    <th>11:30</th>
                                                    <th>12:00</th>
                                                    <th>12:30</th>
                                                    <th>13:00</th>
                                                    <th>13:30</th>
                                                    <th>14:00</th>
                                                    <th>14:30</th>
                                                    <th>15:00</th>
                                                    <th>15:30</th>
                                                    <th>-</th>
                                                </tr>
                                                <tr>
                                                    <td v-for="(item, idx) of consTimeList.slice(0,15)" :key="idx"><input type="checkbox" v-model="item.use" :disabled="item.can === false" /> </td>
                                                </tr>
                                                <tr>                                                    
                                                    <th>16:00</th>
                                                    <th>16:30</th>
                                                    <th>17:00</th>
                                                    <th>17:30</th>
                                                    <th>18:00</th>
                                                    <th>18:30</th>
                                                    <th>19:00</th>
                                                    <th>19:30</th>
                                                    <th>20:00</th>
                                                    <th>20:30</th>
                                                    <th>21:00</th>
                                                    <th>21:30</th>
                                                    <th>22:00</th>
                                                    <th>22:30</th>
                                                    <th>23:00</th>
                                                </tr>
                                                <tr>
                                                    <td v-for="(item, idx) of consTimeList.slice(15,30)" :key="idx"><input type="checkbox" v-model="item.use" :disabled="item.can === false" /> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <!-- 상담사는 필요없는 부분이다. 상담사, 상담회기 -->
                                <tr v-if="auth !== 'STAFF'">
                                    <th class="bold">상담사</th>
                                    <td class="left pl-20" colspan="3">
                                        <div id="app" v-on:keyup.down="selectVsalue('down')" v-on:keyup.up="selectValue('up')">
                                            <div class="search">
                                                <input type="text" v-model="staffName" class="ml-10" style="width:200px;" id="counselor" name="counselor" @input="watchEvent($event, 'counselor')">
                                                <ul class="r" tabindex="0" v-bind:class="{ show: isAutoCounselor }" v-on:mouseover="removeValue">
                                                    <li tabindex="-1" v-for="(el, index) in filterCounselorList" v-on:click="changeValue(el.name, 'counselor', el.idx, el.idx_crm_center, el.center_name)" v-on:keyup.enter="selectValue('enter', el.name, el.idx, el.idx_crm_center, el.center_name)" :key="index">
                                                        <span>{{ el.name }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="auth != 'STAFF'">
                                    <th>상담유형</th>
                                    <td class="left pl-30">
                                        <select v-model="idxCrmMetaType" class="w-200px">
                                            <option value="-1">상담유형선택</option>
                                            <option v-for="(item, index) of typeList" :key="index" :value="item.idx">{{item.type}}</option>
                                        </select>
                                    </td>
                                    <th>진행상황 <span class="red">*</span></th>
                                    <td class="left pl-20">
                                        <select v-model="clientStatus" class="w-120px">
                                            <option value="">진행상황</option>
                                            <option value="진행">진행</option>
                                            <option value="종결">종결</option>
                                            <option value="홀딩">홀딩</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th v-if="auth != 'STAFF'">상담구분</th>
                                    <td v-if="auth != 'STAFF'" class="left pl-30">
                                        <select v-model="gubun" @change="getProductList()" class="w-120px">
                                            <option value="구분">구분</option>
                                            <option v-for="(item, index) of gubunList" :key="index" :value="item.type">{{item.type}}</option>
                                        </select>
                                        <select v-model="idxCrmMetaProduct" class="w-150px ml-10">
                                            <option value="-1">상품명</option>
                                            <option v-for="(item, index) of productList" :key="index" :value="item.idx">{{item.product}} ({{item.price}}원)</option>
                                        </select>
                                        <span class="left pl-20">기타권 금액</span>
                                        <span class="left pl-20"><input type="text" v-model="etcPrice" class="w-60px"> 원</span>
                                    </td>
                                    <th>상담 형태 <span class="red">*</span></th>
                                    <td class="left pl-30">
                                        <select class="w-200px" v-model="idxCrmMetaContact">
                                            <option value="">상담 형태 선택</option>
                                            <option v-for="(item,index) in contactList" :key="index" :value="item.type">
                                                {{ item.type }}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                <th style="width: 111px;">메모</th>
                                    <td class="left pl-20" colspan="3">
                                        <textarea type="text" v-model="memo" class="ml-10 w-98per h-100px" id="memo" name="memo" value="" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="btns3 mgB80">
                            <a class="btn_sms mr-10 pointer" @click="$emit('update:onModifyModal', !onModifyModal)">닫기</a>
                            <a class="btn_cancle mr-10 pointer" @click="modify()">수정</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
import {
    EmptyCheck
} from '@/utils/validation.js'
import store from '@/store/index';
export default {

    props: {
        onModifyModal: {
            type: Boolean,
            require: true,
            default: false
        },
        
    },

    data: () => ({

        consTimeList: [],
        name: '',
        consDate: '',
        consTime: '',
        customerType: 'new',

        gender: '',
        birth: '',
        email: '',
        phone1: '010', // 휴대폰 앞자리
        phone2: '', // 휴대폰 가운데자리
        phone3: '', // 휴대폰 마지막자리
        routeList: [],
        idxCrmMetaRoute: -1,
        isAutoCounselor: false, // 상담사 자동완성 on/off
        autoSearchCounselor: '', // 상담사 자동완성 검색
        counselorList: [], // 상담사 리스트 (자동완성)
        isAutoClient: false, // 유저 자동완성 on/off
        autoSearchClient: '', // 유저 자동완성 검색
        clientList: [], // 유저 리스트 (자동완성)

        companyName: '',
        companyList: [],
        isAutoCompany: false, // 소속(컴퍼니) 자동완성 on/off
        autoSearchCompany: '', // 소속(컴퍼니) 자동완성 검색
        idxCrmCompany: -1,


        workTimeList: [],
        typeList: [],
        idxCrmMetaType: -1,
        clientStatus: '',
        reservationStatus: '',
        eapYn: 'N',
        idxCrmPerson: -1,
        idxCrmClient: -1,
        memo: '',
        staffName: '',
        idxCrmStaff: -1,
        idxCrmReservation: -1,
        consStartDate: '',
        ageRange :'',
        idxTypeMeta:-1 ,//상담형태
        auth: store.state.userInfo.auth,

        gubunList:[],
        productList:[],//상품리스트

        gubun:'구분',//상담구분
        idxCrmMetaProduct:-1,//상담구분 하위
        
        idxCrmMetaContact:'',//상담형태
        contactList:[],// 상담형태 리스트
        etcPrice:0,//기타권 금액
    }),


    computed: {
        filterCounselorList() {
            const str = this.autoSearchCounselor
            const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str)
            if (reg === false && str !== '' && str !== ' ') {
                this.isAutoCounselor = true
                var filterNames = []
                filterNames = this.counselorList.filter((el) => {
                    return el.name.match(str);
                });
                if (filterNames.length > 0) {
                    return filterNames
                } else {
                    this.isAutoCounselor = false
                }
            } else {
                this.isAutoCounselor = false
            }
        },

    },

    mounted() {
        this.consDate = this.formatDate(this.consStartDate);
        this.getCounselorList()
        this.getMetaList()
        
        this.getConTypeMeta();
        this.setConsTime();
        this.getGubunList();
    },

    methods: {
        handleWrapperClick() {
            this.$emit('update:onModifyModal', false)
        },

        getPersonData(idx) {
            var params = {
                idx: idx
            }
            this.axios.get(`/api/v1/client/person/${idx}`, {
                })
                .then(res => {
                    if (res.data.err === 0) {

                        this.name = res.data.personVO.name
                        this.gender = res.data.personVO.gender
                        this.birth = res.data.personVO.birth
                        this.idxCrmMetaRoute = res.data.personVO.idxCrmMetaRoute
                        this.email = res.data.personVO.email;
                        this.ageRange = res.data.personVO.ageRange;

                        // 휴대폰 번호 매핑
                        if(res.data.personVO.phone !== null) {
                            var phone = res.data.personVO.phone.split('-')
                            if (phone.length === 3) {
                                this.phone1 = phone[0]
                                this.phone2 = phone[1]
                                this.phone3 = phone[2]
                            }
                        }
                    }
                })
        },

        async getReservation(idx) {
            await this.axios.get(`/api/v1/cons/${idx}`, {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.idxCrmReservation = res.data.resVO.idx;
                        this.staffName = res.data.resVO.staffName;
                        this.consDate = res.data.resVO.consDate;
                        this.consStartDate = res.data.resVO.consDate;
                        this.consTime = res.data.resVO.consTime;
                        this.reservationStatus = res.data.resVO.reservationStatus
                        this.idxCrmClient = res.data.resVO.idxCrmClient
                        this.idxCrmCenter = res.data.resVO.idxCrmCenter
                        this.clientName = res.data.resVO.name
                        this.idxCrmStaff = res.data.resVO.idxCrmStaff
                        this.reportYn = res.data.resVO.reportYn;
                        this.idxCrmMetaContact = res.data.resVO.contactType;

                        this.companyName = (res.data.resVO.companyName=="") ? "B2C" : res.data.resVO.companyName ;
                        this.idxCrmCompany = res.data.resVO.idxCrmCompany;
                        
                        // 근무요일 테이블 매핑 (자기 자신거)
                        this.workTimeList = res.data.resVO.consTime.split('/')
                        for (var workTime of this.workTimeList) {
                            for (var consTime of this.consTimeList) {
                                for (var work of this.workTimeList) {
                                    //console.log('work ', work, ' time', consTime.time)
                                    if (work === consTime.time) {
                                        consTime.can = false
                                        consTime.use = true
                                    }
                                }
                            }
                        }
                    }

                    this.getStaffSchedule() // 상담사 스케쥴
                }).catch(err => {
                    console.error(err)
                    if (err.response.data.path === '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        async getCustomerData(idx) {
            await this.axios.get(`/api/v1/client/${idx}`, {
                })
                .then(res => {
                    if (res.data.err === 0) {
                        
                       if (res.data.clientVO.staffName !== '') {
                                this.staffName = res.data.clientVO.staffName
                                this.idxCrmStaff = res.data.clientVO.idxCrmStaff
                        }
                        
                        if(res.data.clientVO.idxCrmCompany !== '') {
                            this.idxCrmCompany = res.data.clientVO.idxCrmCompany
                        }
                        this.gubun = res.data.clientVO.gubun || '구분';
                        this.idxCrmMetaType = res.data.clientVO.idxCrmMetaType;
                        this.clientStatus = res.data.clientVO.clientStatus;
                        this.idxCrmPerson = res.data.clientVO.idxCrmPerson;
                        this.idxCrmMetaProduct = res.data.clientVO.idxCrmMetaProduct;
                        this.companyName = (res.data.clientVO.companyName=="") ? "B2C" : res.data.clientVO.companyName ;
                        this.idxCrmCompany = res.data.clientVO.idxCrmCompany;
                        this.etcPrice = res.data.clientVO.etcPrice;
                        this.getPersonData(res.data.clientVO.idxCrmPerson);
                        this.getProductList();
                    }
                })
        },

        

        postMemo() {
            var vo = {
                memo: this.memo,
                idxCrmPerson: this.idxCrmPerson,
            }

            this.axios.post('/api/v1/client/memo', vo)
                .then(res => {
                    if (res.data.err === 0) {
                        //alert('메모 등록이 완료되었습니다.')
                        //this.$router.push('/origin/customer_manage')
                        //this.$router.go(this.$router.currentRoute)
                    } else {
                        alert(res.data.result)
                    }
                })
               .catch(err => {
                    console.error(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        modify() {

            let idx = this.idxCrmReservation
            // 근무요일 테이블 파라미터형태로 변경
            let consTimeList = []
            let copyConsTimeList = JSON.parse(JSON.stringify(this.consTimeList))
            for (var item of copyConsTimeList) {
                if (item.use === true && item.can === true) {
                    delete item['use']
                    consTimeList.push(item)
                }
            }
            
            this.consTime = ''
            consTimeList.reverse();
            for(var time of consTimeList) {
                this.consTime = time.time + "/" + this.consTime
            }

            //당일취소, 당일변경 인 경우에는 시간을 입력하지 않더라도 저장되도록 합니다.
            if(!(this.reservationStatus == "당일변경" || this.reservationStatus == "당일취소")){
                if (!EmptyCheck(this.consTime, '상담시간을')) {
                    return false
                }
            }

            if(!EmptyCheck(this.reservationStatus,"예약현황을")){
                return false;
            }

            if(!EmptyCheck(this.clientStatus,'진행상황을')){
                return false;
            }

            if(!EmptyCheck(this.idxCrmMetaContact.toString(),'상담 형태를')){
                return false;
            }

            let vo = {
                idxCrmStaff: this.idxCrmStaff
                , idxCrmClient: this.idxCrmClient
                , idxCrmMetaType: this.idxCrmMetaType
                , clientStatus: this.clientStatus
                , consDate: this.formatDate(this.consStartDate)
                , consTime: this.consTime
                , reservationStatus: this.reservationStatus
                , idxCrmMetaContact: this.changeContactIdx(this.idxCrmMetaContact)
                , idxCrmMetaProduct: this.idxCrmMetaProduct
                , etcPrice: this.etcPrice
            };
            if (vo.idxCrmClient === -1 ){
                alert("과정을 다시 시도해주세요")
            }else{
                this.axios.put(`/api/v1/cons/cal/${idx}`, vo )
                    .then(res => {
                        if (res.data.err === 0) {
                            alert('수정 되었습니다.');
                            if(this.memo !== '') {
                                this.postMemo()
                            }

                            //고객 차트관리 페이지인 경우..
                            if (location.href.search('customer_manage_chart')>-1){
                                //상담일지 목록 조회 메서드 호출
                                this.$parent.getChartReservationList();
                            }
                        
                            this.closeModal();
                        } else {
                            alert(res.data.result)
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        if (err.response.data.path == '/login/msg') {
                            alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                            this.$router.push('/login')
                        } else {
                            alert('관리자에게 문의하세요')
                        }
                    })
                }
                    
        },

        /**
         * @description : idx로 변경
         */
        changeContactIdx(type){
            const find = this.contactList.find(item => item.type === type);

            return find ? find.idx : -1;
        },

        async getStaffSchedule() {

            let flag = false
            
            // 상담사 자동완성 체크 부분
            for (var staff of this.counselorList) {
                if (staff.name === this.staffName && staff.idx === Number(this.idxCrmStaff)) {
                    flag = true
                    break;
                }
            }
            if( this.idxCrmStaff !== null || this.idxCrmStaff !== 0){
                flag = true;
                if (!flag) {
                    alert('상담사를 다시 확인해주세요')
                    return false
                }
            }
            if (this.idxCrmStaff === -1) {
                alert('존재하지 않는 상담사입니다.')
                return false
            }

            var params = {
                idxCrmStaff: this.idxCrmStaff,
                consDate: this.formatDate(this.consStartDate)
            }

            await this.axios.get(`/api/v1/user/staff/schedule`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {

                        this.isValidCheck = true
                        this.schedule = res.data.schedule
                        this.setConsTime()
                        if (res.data.schedule.length > 0) {
                            for (var i = 0; i < res.data.schedule.length; i++) {
                                // 근무요일 테이블 매핑 (자기 자신거)
                                if (res.data.schedule[i].idx !== Number(this.idxCrmReservation)) {
                                    this.workTimeList = res.data.schedule[i].consTime.split('/')
                                    for (var consTime of this.consTimeList) {
                                        // 상담사 일하는 시간 외에는 선택하지 못하도록 추가 20210118
                                        if (res.data.workTime.work_time.indexOf(consTime.time) === -1) {
                                            consTime.can = false
                                        }
                                        for (var work of this.workTimeList) {
                                            if (work === consTime.time) {
                                                consTime.can = false
                                                consTime.use = true
                                            }
                                        }
                                    }
                                } else {
                                    this.workTimeList = res.data.schedule[i].consTime.split('/')
                                    for (var consTime of this.consTimeList) {
                                        for (var work of this.workTimeList) {
                                            //console.log('work ', work, ' time', consTime.time)
                                            if (work === consTime.time) {
                                                consTime.can = true
                                                consTime.use = true
                                            }
                                        }
                                    }
                                    if (res.data.workTime !== null || res.data.workTime !== undefined) {
                                        for (var consTime of this.consTimeList) {
                                            if (res.data.workTime.work_time.indexOf(consTime.time) === -1) {
                                                consTime.can = false
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            //this.setWorkTimeList()
                            //this.setConsTime()
                            if (res.data.workTime !== null && res.data.workTime !== undefined && res.data.workTime !== 'null') {
                                for (var consTime of this.consTimeList) {
                                    /* if (res.data.workTime.work_time.indexOf(consTime.time) !== -1) {
                                        consTime.can = true
                                    } else {
                                        consTime.can = false
                                    } */
                                    if (res.data.workTime.work_time.indexOf(consTime.time) === -1) {
                                        consTime.can = false
                                    }
                                }
                            } else {
                                for (var consTime of this.consTimeList) {
                                    consTime.can = false
                                }
                            }
                        }
                    }
                }).catch(err => {
                    console.error(err)
                    if (err.response.data.path === '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        // 센터 자동완성 입력 감지
        watchEvent(e, type) {
            if (type === 'counselor') {
                this.autoSearchCounselor = e.target.value
            } else {
                this.autoSearchClient = e.target.value
            }
        },

        // 자동완성 끄기
        removeValue() {
            if (document.querySelector('.r').classList.contains('key')) {
                document.querySelector('.r').classList.remove('key');
                document.querySelector('.r li.sel').classList.remove('sel');
            }
            if (document.querySelector('.c').classList.contains('key')) {
                document.querySelector('.c').classList.remove('key');
                document.querySelector('.c li.sel').classList.remove('sel');
            }
        },

        // 자동완성 선택한 경우 센터명 변경
        changeValue(str, type, idx, centerIdx, centerName) {
            if (type === 'counselor') {
                this.isAutoCounselor = false
                this.staffName = str
                this.idxCrmStaff = idx;
                this.getStaffSchedule()
            } else {
                this.isAutoClient = false
                this.name = str
                this.idxCrmClient = idx
                this.getPersonData(idx)
            }
        },

        // 자동완성 켜기 및 선택
        selectValue(keycode, str, idx, centerIdx, centerName) {
            if (this.isAutoCounselor) {
                const hasClass = document.querySelector('.r').classList.contains('key');
                if (keycode === 'down') {
                    if (!hasClass) {
                        const thisEl = document.querySelectorAll('.r li')[0];
                        document.querySelector('.r').classList.add('key');
                        thisEl.classList.add('sel');
                        thisEl.focus();
                    } else {
                        const lastEl = document.querySelector('.r li:last-child');
                        const thisEl = document.querySelector('.r li.sel');
                        const nextEl = thisEl.nextElementSibling;
                        if (!lastEl.classList.contains('sel')) {
                            thisEl.classList.remove('sel');
                            nextEl.classList.add('sel');
                            nextEl.focus();
                        }
                    }
                }

                if (keycode === 'up' && hasClass) {
                    const firstEl = document.querySelectorAll('.r li')[0];
                    const thisEl = document.querySelector('.r li.sel');
                    const prevEl = thisEl.previousElementSibling;
                    if (!firstEl.classList.contains('sel')) {
                        thisEl.classList.remove('sel');
                        prevEl.classList.add('sel');
                        prevEl.focus();
                    } else {
                        document.querySelector('.s').focus();
                    }
                }

                if (keycode === 'enter' && hasClass) {
                    this.changeValue(str, 'counselor', idx, centerIdx, centerName);
                }
            }

            if (this.isAutoClient) {
                const hasClass = document.querySelector('.c').classList.contains('key');
                if (keycode === 'down') {
                    if (!hasClass) {
                        const thisEl = document.querySelectorAll('.c li')[0];
                        document.querySelector('.c').classList.add('key');
                        thisEl.classList.add('sel');
                        thisEl.focus();
                    } else {
                        const lastEl = document.querySelector('.c li:last-child');
                        const thisEl = document.querySelector('.c li.sel');
                        const nextEl = thisEl.nextElementSibling;
                        if (!lastEl.classList.contains('sel')) {
                            thisEl.classList.remove('sel');
                            nextEl.classList.add('sel');
                            nextEl.focus();
                        }
                    }
                }

                if (keycode === 'up' && hasClass) {
                    const firstEl = document.querySelectorAll('.c li')[0];
                    const thisEl = document.querySelector('.c li.sel');
                    const prevEl = thisEl.previousElementSibling;
                    if (!firstEl.classList.contains('sel')) {
                        thisEl.classList.remove('sel');
                        prevEl.classList.add('sel');
                        prevEl.focus();
                    } else {
                        document.querySelector('.s').focus();
                    }
                }

                if (keycode === 'enter' && hasClass) {
                    this.changeValue(str, 'client', idx);
                }
            }
        },

        // 상담시간 array 구조 생성
        setConsTime() {
            this.consTimeList = []
            let time = 9
            for (var i = 0; i < 29; i++) {
                if (time < 10) {
                    if (i % 2 == 0) {
                        this.consTimeList.push({
                            time: '0' + (time) + ':00',
                            use: false,
                            can: true
                        })
                    } else {
                        this.consTimeList.push({
                            time: '0' + (time) + ':30',
                            use: false,
                            can: true
                        })
                        time += 1
                    }
                } else {
                    if (i % 2 == 0) {
                        this.consTimeList.push({
                            time: (time) + ':00',
                            use: false,
                            can: true
                        })
                    } else {
                        this.consTimeList.push({
                            time: (time) + ':30',
                            use: false,
                            can: true
                        })
                        time += 1
                    }
                }
            }
            this.consTimeList.splice(14, 0, {
                time: "-",
                use: false,
                can: false,
            });
        },

        // 상담사 전체목록 호출, 최대 10만명분을 가져오도록 합니다....
        async getCounselorList() {

          var idxCrmCenter=localStorage.getItem("idxCrmCenter")
          var auth=localStorage.getItem("auth")
          var params = {
            idx: idxCrmCenter,
            loginAuth: auth
          }
          if(params.loginAuth === 'MASTER' || params.loginAuth === 'ADMIN'){
            idxCrmCenter = -1
          }
            await this.axios.get(`/api/v1/center/staff/${idxCrmCenter}`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        //this.counselorList = res.data.userList
                        this.counselorList = res.data.staffList
                    } else {
                        // alert(res.data.result)
                        this.counselorList = []
                    }
                })
                .catch(err => {
                    console.error(err)
                    if (err.response.data.path === '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [year, month, day].join('-');
        },

        postPersonInfo() {

            if (!EmptyCheck(this.name, '고객명을')) {
                return false
            }

            // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            if (this.phone2 !== '' && this.phone3 !== '') {
                var phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3
            } else {
                var phone = ''
            }

            var vo = {
                name: this.name,
                gender: this.gender,
                birth: this.formatDate(this.birth),
                email: this.email,
                idxCrmMetaRoute: this.idxCrmMetaRoute,
                phone: phone
            }

            this.axios.post('/api/v1/client/person', vo)
                .then(res => {
                    if (res.data.err === 0) {
                        this.idxCrmPerson = res.data.idxCrmPerson
                        this.postClientInfo()                        
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.error(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 유입경로, 상담구분, 상담유형 리스트
        async getMetaList() {
            await this.axios.get('/api/v1/client/meta', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.typeList = res.data.typeList;
                        this.routeList = res.data.routeList;
                        this.contactList = res.data.contactList;
                    }
                })
                .catch(err => {
                    console.error(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        /**
         * 상담형태 목록
         */
        async getConTypeMeta(){
            await this.axios.get(`/api/v1/cons/report/type_meta`,{}).then((response)=>{
                this.conTypeList = response.data.data;
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            });
        },

        /**
         * @description : 모달 닫기
         */
        closeModal(){
            this.$emit('modalCloseFromModify');
        },

        /**
         * @description : 구분 리스트
         */
        async getGubunList(){
            await this.axios.get('/api/v1/client/gubun')
                .then(res=>{
                    if (res.data.err === 0) {
                         this.gubunList = res.data.gubunList;
                    }
                    else{
                        this.gubunList = [];
                    }
                })
                .catch(err => {
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        /**
         * @description : 구분에 따른 상품 리스트
         */
        getProductList(){
            let params ={
                value: this.gubun
            };

            this.axios.get('/api/v1/client/gubun',{
                 params: params
            })
            .then(res => {
                if (res.data.err === 0) {
                    this.productList = res.data.productList;
                    const find = this.productList.find((item) => item.idx === this.idxCrmMetaProduct );

                    if(find){
                        this.idxCrmMetaProduct = find.idx;
                    }else{
                        this.idxCrmMetaProduct = -1;
                    }
                }
                else{
                    this.productList = [];
                }
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            });
        },

    }
}
</script>

<style lang="scss" scoped>
.modal {
    &.modal-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 30;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    &-window {
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
    }

    &-content {
        padding: 10px 20px;
        background: #fff;
    }

    &-footer {
        // background: #ccc;
        padding: 10px;
        text-align: right;
    }
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.4s;

    .modal-window {
        transition: opacity 0.4s, transform 0.4s;
    }
}

.modal-leave-active {
    transition: opacity 0.4s ease 0.2s;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;

    .modal-window {
        opacity: 0;
        transform: translateY(-20px);
    }
}

#app {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .search {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;

        .s {
            float: left;
            margin-left: 15px;
            width: 255px;
            height: 30px;
            object-fit: contain;
            border-radius: 3px;
            border: solid 1px #cccccc;
            background-color: #ffffff;
            padding-left: 15px;

            &::placeholder {
                font-size: 14px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 4.29;
                letter-spacing: normal;
                text-align: left;
                color: #cccccc;
            }
        }

        .c {
            display: none;
            position: absolute;
            left: 0;
            top: 28px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
                z-index: 10;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 91%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        .r {
            display: none;
            position: absolute;
            left: 0;
            top: 28px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
                z-index: 10;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 91%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        p {
            padding: 10px 0;
            text-align: right;
            font-size: 12px;
        }
    }
}

.point {
    font-family: gulim;
    font-size: 9pt;
    color: #FAF7E7;
}

.textbox {
    font-family: gulim;
    font-size: 9pt;
    color: #736257;
    height: 20px;
}

.msg_wrap {
    width: 230px;
}

.msg_deco_wrap {
    border-radius: 13px 13px 0 0;
    background: #efefef;
    padding: 12px 0 0 0;
}

.msg_deco {
    width: 40px;
    height: 2px;
    background: #ababab;
    border-radius: 20px;
    margin: 0 auto;
}

.msg_box {
    background: #efefef;
    padding: 8px 20px 10px 20px;
    display: block;
    height: 200px;
}

.msg_box textarea {
    width: 100%;
    height: 100%
}

.msg_send {
    background: #efefef;
    padding: 0 20px 20px 20px;
}

.msg_footer {
    background: #efefef;
    border-radius: 0 0 13px 13px;
    padding: 0 20px 20px 20px;
    text-align: center;
}
</style>
